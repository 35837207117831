@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
}

.Mui-selected {
  background-color: rgb(0, 148, 227) !important;
  color: #fff !important;
}

.MuiPickersPopper-paper {
  box-shadow: 0px 0px 20px rgba(132, 132, 132, 0.2) !important;
  border-radius: 16px !important;
  margin-top: 1px;
}

.MuiOutlinedInput-root {
  color: #050505 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
}

.Mui-disabled {
  cursor: not-allowed;
}

.primary-anchor {
  width: max-content;
  min-width: 115px;
  min-height: 46px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  transition: all 0.3s ease 0s;
  font-weight: 500;
  border: 1px solid rgb(221, 1, 91);
  color: rgb(255, 255, 255);
  background-color: rgb(221, 1, 91);
  overflow: hidden;
  font-size: 14px;
  border-radius: 12px;
  text-decoration: none;
  /* Remove default underline for anchor tags */
}

.primary-anchor:hover {
  background-color: rgba(221, 1, 91, 0.698);
  border-color: rgba(221, 1, 91, 0.698);
  transform: scale(1.004);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 10px 2px;
}

.single-select {
  border-radius: 6px;
  height: 50px;
  color: rgb(0, 0, 0);
  width: 335px;
  box-sizing: initial;
  outline: none;
}

.single-select input {
  padding: 16px 12px;
  border-radius: 6px;
  width: 335px;
  height: 38px;
}

.custom-error-message {
  position: relative;
  font-size: 12px;
  font-weight: 400;
  color: rgb(238, 0, 4);
  margin-top: 8px;
}

.report-filter {
  justify-content: flex-end !important;
}

@media (max-width: 600px) {
  .MuiIconButton-root {
    display: inline-flex;
  }
}

@media (max-width:768px) {

  .table-data-framework-context {
    width: 1500px !important;
  }

  .table-data-tm {
    width: 1400px !important;
  }

  .table-data-account {
    width: 1000px !important;
  }

  .report-filter {
    justify-content: flex-start !important;
  }

  .integration-panel {
    flex-direction: column !important;
  }

}