@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
/* .paginate-ctn{
    display: flex;
} */
.round-effect{
  color:#0094E3;
  background-color: #fff;
  height: 32px;
  width: 32px;
  border-radius: 6px;
  border : 1px solid #ecf0ff;
  cursor: pointer;
  margin:5px;
}

.prevNext{
    color:#8A8A8A;
    background-color: #fff;
    height: 32px;
    width: 32px;
    border-radius: 6px;
    border : 1px solid rgba(0, 0, 0, 0.10);
    cursor: pointer;
    margin:5px;   
    padding: 8px 12px;
}

.round-effect:hover{
  text-decoration: none;
  /* background: #0094E3; */
  border : 1px solid #0094E3;
  color:#0094E3;
}

.prevNext:hover{
    text-decoration: none;
    /* background:#0094E3; */
    border : 1px solid #0094E3;
    color:#0094E3;
}

.pageActive{
  background:#FFF;
  border : 1px solid #0094E3;
  color:#0094E3;
}

.prevNext.disabled {
    cursor: not-allowed;
    background-color: rgba(0,0,0,0.1);
    color: #999;
    border-color: #ecf0ff;
}
