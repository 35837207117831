* {
  font-family: "Inter";
}

@font-face {
  font-family: "Inter-Black";
  src: local("Inter"), url("./fonts/Inter-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Inter-Bold";
  src: local("Inter"), url("./fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Inter-ExtraBold";
  src: local("Inter"), url("./fonts/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Inter-ExtraLight";
  src: local("Inter"),
    url("./fonts/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Inter-Light";
  src: local("Inter"), url("./fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Inter-Medium";
  src: local("Inter"), url("./fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter-SemiBold";
  src: local("Inter"), url("./fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Inter-Thin";
  src: local("Inter"), url("./fonts/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
}

.table-container {
  width: 100%;
  overflow: auto;
  font-size: 14px;
  border-radius: 10px;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
}

th,
td {
  padding: 6px 10px;
  border: 1px solid #ddd;
  text-align: left;
  min-width: 100px;
}

th {
  background-color: #f2f2f2;
}

.fixed-header {
  position: sticky;
  top: 0;
  background-color: rgb(248, 248, 248);
  z-index: 1;
}

.fixed-column {
  position: sticky;
  left: 0;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  box-shadow: 2px 0 5px -2px rgba(0,0,0,0.3);
  z-index: 1;
}

th.fixed-column {
  z-index: 2;
}

.fixed-column::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: #ddd; /* Match the color of your border */
  z-index: 2; /* Ensures the pseudo-element stays above the column */
}