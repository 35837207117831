/* CarouselComponent.css */
.carousel-item {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    
  }
  
  .carousel-item.active {
    opacity: 1;
  }
  .slider.animated {
    transform: none !important;
  }