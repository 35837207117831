select {
    padding: 5px;          /* Space inside the select box */
    margin: 5px;           /* Space around each select element */
    border-radius: 4px;    /* Rounded corners */
    border: 1px solid #ccc;/* Border with a light gray color */
    font-size: 14x;       /* Font size of the text within the select */
    background-color: #f9f9f9; /* Light background color */
    color: #57585C;           /* Dark text color */
  }
  
  select:focus {
    border-color: #007bff; /* Border color when focused */
    outline: none;         /* Remove default outline on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Shadow effect on focus */
  }