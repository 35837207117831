h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .faq-group {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .faq-group-header {
    font-size: 18px;
    font-weight: bold;
    background-color: #f7f7f7;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .faq-group-content {
    padding: 10px;
  }
  
  .faq-item {
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow to each question */
    overflow: hidden; /* Ensure rounded corners are preserved */
    transition: transform 0.3s ease; /* Add a hover effect for subtle scaling */
  }
  
  
  .faq-question {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fff;
  }
  
  .faq-answer {
    overflow: hidden;
    max-height: 0;
    background-color: #f9f9f9; /* Add a slight background color for answers */
  }
  
  .faq-answer p {
    margin: 0;
    padding: 10px 0 10px 10px;
  }
  
  .arrow {
    font-size: 16px;
    transition: transform 0.3s ease;
  }