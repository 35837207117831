.file-upload-wrapper {
    display: flex;
    align-items: center;
    margin-top: 6px;
    border: 1px solid #ced4da; /* Bootstrap border color */
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    width: 100%;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.custom-file-label {
    cursor: pointer;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
    background-color: transparent;
    color: #495057;
    font-weight: normal;
    transition: color 0.2s ease-in-out;
}

.file-name {
    flex-grow: 1;
    font-size: 16px;
    color: #495057; /* Bootstrap text color for inputs */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-upload-wrapper:hover,
.file-upload-wrapper:focus-within {
    border-color: #80bdff; /* Highlight border on focus or hover */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}