.paginationBttns {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 10px;
}

.paginationBttns a {
  width: 32px;
  height: 32px;
  margin: 8px;
  background: #ffffff;
  border: 1px solid #ecf0ff;
  border-radius: 8px;
  color: #050505;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationBttns a:hover {
  color: white;
  background-color: #0094e3;
}

.paginationActive a {
  background: #ffffff;
  border: 1px solid #0094e3;
  border-radius: 8px;
  color: #0094e3;
}

.paginationDisabled a {
  color: #8a8a8a;
  cursor: not-allowed;
}

.paginationDisabled svg {
  fill: #8a8a8a;
}

.paginationDisabled path {
  fill: #8a8a8a;
}
.paginationDisabled a:hover {
  background: #ffffff;
  border: 1px solid #ecf0ff;
  color: #8a8a8a;
}

.previousBttn:hover path {
  fill: #ffffff;
}

.nextBttn:hover path {
  fill: #ffffff;
}
