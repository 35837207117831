.kebab-menu {
  position: relative;
  display: inline-block;
}

.kebab-icon {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.menu-content {
  position: absolute;
  right: 40%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 8px 18px;
  min-width: max-content;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, background-color 0.3s, color 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-content-new {
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 8px 18px;
  min-width: max-content;
  transform-origin: top;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, background-color 0.3s, color 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-content div { 
  transition: background-color 0.3s, color 0.3s;
}

.kebab-menu.open .menu-content {
  opacity: 1;
  transform: scaleY(1);
}
